import { Info } from 'assets/icons';
import { Button } from 'components/Buttons';
import Divider from 'components/Divider';
import Checkbox from 'components/FormElements/Checkbox';
import TextArea from 'components/FormElements/TextArea';
import Modal, { ModalHeader } from 'components/Modal';
import Tooltip from 'components/Tooltip';
import { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    BetaWrapper,
    ButtonWrapper,
    CheckboxWrapper,
    InfoParagraph,
    ModalContent,
    SubHeading,
} from './StyledFeedbackModal';

type Props = {
    show: boolean;
    onClose: () => void;
    onSubmit: (data: InsightFeedbackForm | null) => void;
};

export const insightFeedbackOptions = [
    'numberOfShipments',
    'amount',
    'shipmentsPerCarrier',
    'costPerCarrier',
    'types',
    'dimensions',
    'destination',
];
type Option = typeof insightFeedbackOptions[number];

export interface InsightFeedbackForm {
    desiredStatistics: Set<Option>;
    otherDesiredStatistic: string;
    feedback: string;
    betaTester: boolean;
}

const FeedbackModal = ({ show, onClose, onSubmit }: Props) => {
    const [showOtherInput, setShowOtherInput] = useState(false);

    const { t } = useTranslation('dashboard');

    const { control, register, handleSubmit } = useForm<InsightFeedbackForm>({
        defaultValues: {
            desiredStatistics: new Set(),
            betaTester: false,
        },
        shouldUnregister: false, // So text is kept if the user accidentally unselects 'other input'
    });

    const onFormSubmit = (data: InsightFeedbackForm) => {
        const submittedData = { ...data };
        if (!showOtherInput) {
            // We should not send data from the now hidden input area, if its checkbox has been deselected.
            submittedData.otherDesiredStatistic = '';
        }
        onSubmit(submittedData);
    };

    return (
        <Modal width="600px" showModal={show} onClose={onClose}>
            <ModalHeader title={t('insights.experiment.feedbackModal.title')} />
            <ModalContent>
                <InfoParagraph>
                    <Text>{t('insights.experiment.feedbackModal.body')}</Text>
                </InfoParagraph>
                <SubHeading size={HeadingSize.SMALL}>
                    {t('insights.experiment.feedbackModal.checkboxTitle')}
                </SubHeading>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <CheckboxWrapper>
                        <Controller
                            name="desiredStatistics"
                            control={control}
                            render={({
                                value,
                                onChange,
                            }: {
                                value: Set<Option>;
                                onChange: (value: Set<Option>) => void;
                            }) => (
                                <>
                                    {insightFeedbackOptions.map((option) => (
                                        <Checkbox
                                            key={option}
                                            value={option}
                                            id={option}
                                            checked={value.has(option)}
                                            onValueChange={(
                                                isChecked: boolean
                                            ) => {
                                                const valueCopy = new Set(
                                                    value
                                                );
                                                if (isChecked) {
                                                    valueCopy.add(option);
                                                } else {
                                                    valueCopy.delete(option);
                                                }
                                                onChange(valueCopy);
                                            }}
                                            label={t(
                                                `insights.experiment.feedbackModal.checkboxes.${option}`
                                            )}
                                        />
                                    ))}
                                </>
                            )}
                        />
                        <Checkbox
                            checked={showOtherInput}
                            onValueChange={setShowOtherInput}
                            label={t(
                                'insights.experiment.feedbackModal.checkboxes.other'
                            )}
                            id="otherInput"
                        />
                    </CheckboxWrapper>
                    {showOtherInput && (
                        <TextArea
                            name="otherDesiredStatistic"
                            id="otherDesiredStatistic"
                            ref={register()}
                            label={t(
                                'insights.experiment.feedbackModal.checkboxes.otherInput'
                            )}
                        />
                    )}
                    <Divider />
                    <SubHeading size={HeadingSize.SMALL}>
                        {t('insights.experiment.feedbackModal.otherFeedback')}
                    </SubHeading>
                    <TextArea
                        name="feedback"
                        id="feedback"
                        ref={register()}
                        label={t(
                            'insights.experiment.feedbackModal.otherFeedbackInput'
                        )}
                    />
                    <BetaWrapper>
                        <Controller
                            name="betaTester"
                            control={control}
                            render={({
                                value,
                                onChange,
                            }: {
                                value: boolean;
                                onChange: (value: boolean) => void;
                            }) => (
                                <>
                                    <Checkbox
                                        value="beta"
                                        checked={value}
                                        onValueChange={onChange}
                                        label={t(
                                            'insights.experiment.feedbackModal.betaTest'
                                        )}
                                        id="beta"
                                    />
                                </>
                            )}
                        />
                        <Info data-tooltip-id="tooltip-feedback-modal-beta" />
                    </BetaWrapper>
                    <ButtonWrapper>
                        <Button
                            variant="secondary"
                            onClick={() => onSubmit(null)}
                        >
                            {t('insights.experiment.feedbackModal.skip')}
                        </Button>
                        <Button type="submit" data-testid="submit-feedback">
                            {t('insights.experiment.feedbackModal.send')}
                        </Button>
                    </ButtonWrapper>
                </form>
            </ModalContent>
            <Tooltip id="tooltip-feedback-modal-beta">
                <Text>
                    {t('insights.experiment.feedbackModal.betaTestInfo')}
                </Text>
            </Tooltip>
        </Modal>
    );
};

export default FeedbackModal;
