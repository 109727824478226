import styled from 'styled-components/macro';

import Paragraph from '../Typography/Paragraph';

export const InfoWrapper = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.colors.brown300};
    display: flex;
    gap: ${({ theme }) => theme.space.s2};
    padding: ${({ theme }) => theme.space.s3};
    text-align: left;
    width: 100%;
`;
export const Content = styled.div`
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
`;

export const InfoText = styled(Paragraph)`
    margin: 0;
`;
