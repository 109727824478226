import * as Sentry from '@sentry/react';
import Badge from 'components/Badge';
import { Button } from 'components/Buttons';
import { CountryOption } from 'components/FormElements/CountrySelect/CountrySelect';
import {
    Option as BaseOption,
    SelectInstance,
} from 'components/FormElements/Select';
import Switch from 'components/FormElements/Switch';
import AdditionalAddressLines from 'components/NewShipmentForm/components/AddressForm/AdditionalAddressLines';
import Tooltip from 'components/Tooltip';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import { countriesWithDivisionSelection } from 'constants/subnationalDivisions';
import { TwoColumnNotification } from 'containers/Compare/FreightForwarding/Party/AddressForm/StyledAddressForm';
import { InfoTooltipIcon } from 'containers/Compare/ProductList/StyledProductList';
import { StarIcon } from 'containers/Compare/SendifyInsurance/StyledSendifyInsurance';
import { trackHomeButtonClick } from 'external/analytics';
import {
    getStateOptions,
    scrollToAndFocus,
    scrollToSelect,
} from 'helpers/FormHelper';
import { getCountryFromDomain } from 'helpers/SystemCountryHelper';
import { supportsReceiverPays } from 'helpers/TeamHelper';
import { useTeam, useUser } from 'hooks/Queries';
import { useGoogleAutoComplete } from 'hooks/useGoogleAutoComplete';
import { usePersistentState } from 'hooks/usePersistentState';
import { Alpha2Code } from 'i18n-iso-countries';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddressBookSearch, { Option } from './AddressBookSearch';
import {
    FirstColumnInput,
    Header,
    InputGrid,
    SecondColumnInput,
    StyledTip,
    ToggleWrapper,
    TwoColumnCountrySelect,
    TwoColumnInput,
    TwoColumnSelect,
    TwoColumnToggle,
    Wrapper,
} from './StyledAddressForm';

export type ConsignorType = 'sender' | 'recipient';

export interface AddressFormProps {
    prefix: string;
    title: string;
    consignor: ConsignorType;
    hideIsPrivate?: boolean;
    isEditing?: boolean;
    countryOverride?: Alpha2Code;
    qqComplementValues?: {
        postalCode?: string;
        countryCode: Alpha2Code;
        state?: string;
        city: string;
        isPrivate: boolean;
        receiverPaysAccountNumber?: string;
    };
    // Email addresses are always required for international shipments
    shipmentType?: 'domestic' | 'international';
}

const AddressForm = (props: AddressFormProps) => {
    const {
        prefix,
        title,
        consignor,
        hideIsPrivate,
        isEditing,
        countryOverride,
        qqComplementValues,
        shipmentType,
    } = props;

    const { user } = useUser();
    const { team } = useTeam();
    const [stateList, setStateList] = useState<
        {
            label: string;
            value: string;
        }[]
    >([]);

    const {
        errors,
        setValue,
        getValues,
        reset,
        control,
        trigger,
        setError,
        clearErrors,
        formState,
    } = useFormContext();
    const { isSubmitSuccessful } = formState;

    const [contactOption, setContactOption] = useState<Option | null>(null);
    const [showReceiverPays, setShowReceiverPays] = useState<boolean>(false);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const addressLine1InputRef = useRef<HTMLInputElement>(null);
    const cityInputRef = useRef<HTMLInputElement>(null);
    const postalCodeInputRef = useRef<HTMLInputElement>(null);
    const stateSelectorRef = useRef<SelectInstance<BaseOption>>(null);
    const countrySelectorRef = useRef<SelectInstance<CountryOption>>(null);
    const contactNameInputRef = useRef<HTMLInputElement>(null);
    const contactPhoneInputRef = useRef<HTMLInputElement>(null);
    const contactEmailInputRef = useRef<HTMLInputElement>(null);
    const receiverPaysInputRef = useRef<HTMLInputElement>(null);
    const [newFeatureBadgeCutoff] = usePersistentState<DateTime>(
        'receiverPaysNewFeature',
        DateTime.now().plus({ days: 30 })
    );
    const countryCode = useWatch<Alpha2Code>({
        control,
        name: `${prefix}.countryCode`,
    });

    useEffect(() => {
        if (countryOverride) {
            setValue(`${prefix}.countryCode`, countryOverride);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryOverride]);

    const receiverPays = useWatch<string>({
        control,
        name: `${prefix}.receiverPaysAccountNumber`,
    });

    useEffect(() => {
        setShowReceiverPays(!!receiverPays);
    }, [receiverPays]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            setShowReceiverPays(false);
            setContactOption(null);
        }
    }, [isSubmitSuccessful]);

    const isPrivate = useWatch({
        control,
        name: `${prefix}.isPrivate`,
        defaultValue: false,
    });

    const { t } = useTranslation(['address']);

    const updateStates = async () => {
        setStateList(await getStateOptions(countryCode));
    };
    const { addressLine1, city, country, state, postalCode } =
        useGoogleAutoComplete(
            document.getElementById(`${prefix}.addressLine1`),
            !qqComplementValues
        );

    useEffect(() => {
        // Don't set the country from Google if it's originally overridden.
        if (!countryOverride) {
            if (country) {
                setValue(`${prefix}.countryCode`, country);
            } else {
                setValue(`${prefix}.country`, team?.systemCountry);
            }
        }
        /*
            If we have a country override we should not allow Google to set the state,
            unless the country override is the same as the country the state is in.
            This is because we don't set the country, either, if its originally
            overwritten, and trying to set the state in this case would likely
            cause a mismatch.

            We should also not allow this if the country in question does not use
            states in our system.
        */
        const allowStateSetting =
            !countryOverride || country === countryOverride;
        if (allowStateSetting && state !== undefined) {
            setValue(`${prefix}.state`, state);
        }

        if (postalCode === null) {
            setError(`${prefix}.postalCode`, {
                message: t('address:noPostCodeFound'),
            });
        }
        if (addressLine1) {
            setValue(`${prefix}.addressLine1`, addressLine1);
        }
        if (city) {
            setValue(`${prefix}.city`, city);
        }
        if (postalCode) {
            clearErrors(`${prefix}.postalCode`);
            setValue(`${prefix}.postalCode`, postalCode);
        }
    }, [
        addressLine1,
        city,
        country,
        state,
        postalCode,
        prefix,
        setValue,
        countryOverride,
        team?.systemCountry,
        setError,
        t,
        clearErrors,
    ]);

    useEffect(() => {
        updateStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    const fillHomeAddress = async () => {
        try {
            const homeAddress = {
                [`${prefix}.isPrivate`]: false,
                [`${prefix}.name`]: team?.name || '',
                [`${prefix}.addressLine1`]:
                    team?.homeAddress?.addressLine1 || '',
                [`${prefix}.addressLine2`]:
                    team?.homeAddress?.addressLine2 || '',
                [`${prefix}.addressLine3`]:
                    team?.homeAddress?.addressLine3 || '',
                [`${prefix}.city`]: team?.homeAddress?.city || '',
                [`${prefix}.postalCode`]: team?.homeAddress?.postalCode || '',
                [`${prefix}.countryCode`]:
                    countryOverride ||
                    team?.homeAddress?.countryCode ||
                    team?.systemCountry,
                [`${prefix}.contactName`]: `${user?.firstName || ''} ${
                    user?.lastName || ''
                }`,
                [`${prefix}.contactPhone`]: user?.mobileNumber || '',
                [`${prefix}.contactEmail`]: user?.email || '',
            };

            Object.keys(homeAddress).forEach((key) =>
                setValue(key, homeAddress[key])
            );
            trigger(Object.keys(homeAddress));
            setContactOption(null);
            trackHomeButtonClick(prefix);
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    useEffect(() => {
        if (contactOption) {
            // The user has selected a contact from their address book.
            const { contact, person } = contactOption.value;

            let contactName = '';
            let contactEmail = '';
            let contactPhone = '';

            if (person) {
                contactName = person.lastName
                    ? `${person.firstName} ${person.lastName}`
                    : person.firstName;
                contactEmail = person.email;
                contactPhone = person.mobileNumber;
            }

            const country =
                countryOverride ||
                contact.address.countryCode ||
                team?.systemCountry;

            const name = contact.isPrivate ? contactName : contact.name;
            const newAddress = {
                [`${prefix}.isPrivate`]: contact.isPrivate || false,
                [`${prefix}.name`]: name,
                [`${prefix}.addressLine1`]: contact.address.addressLine1 || '',
                [`${prefix}.addressLine2`]: contact.address.addressLine2 || '',
                [`${prefix}.addressLine3`]: contact.address.addressLine3 || '',
                [`${prefix}.city`]: contact.address.city || '',
                [`${prefix}.postalCode`]: contact.address.postalCode || '',
                [`${prefix}.countryCode`]:
                    countryOverride ||
                    contact.address.countryCode ||
                    team?.systemCountry,
                [`${prefix}.contactName`]: contactName,
                [`${prefix}.contactPhone`]: contactPhone,
                [`${prefix}.contactEmail`]: contactEmail,
            };
            Object.keys(newAddress).forEach((key) =>
                setValue(key, newAddress[key])
            );

            /**
             *  States are dependent on the country. The state value is
             * therefore set separately to avoid belayed error triggers
             * (if a user switches from an adress with a state to one
             * without an error will otherwise be shown, since the state
             * list hasn't had time to update).
             *
             * We also need to check if the country of the contact should
             * use states. We had an issue where Google Maps prefilled
             * state values for countries like Norway, and some of these
             * might still be in the system.
             */
            if (
                country === contact.address.countryCode && // Check that the country is not overridden
                countriesWithDivisionSelection.has(country)
            ) {
                setValue(`${prefix}.state`, contact.address.state || '');
            }
            if (contact.dhlCarrierAccount) {
                setValue(
                    `${prefix}.receiverPaysAccountNumber`,
                    contact.dhlCarrierAccount
                );
                setShowReceiverPays(true);
            }
        }
    }, [contactOption]);

    /**
     * If the user has entered a postal code, then check if that is the same as the one stored
     * in the team's home address. If not, check if the cities are the same.
     * Ensure that the state and country code are also the same.
     * Ensure that we have loaded the user
     * If any of these fail, disable the fill home address button.
     */
    const disableFillHomeAddress =
        (qqComplementValues &&
            !(
                ((qqComplementValues.postalCode &&
                    qqComplementValues.postalCode ===
                        team?.homeAddress?.postalCode) ||
                    qqComplementValues.city === team?.homeAddress?.city) &&
                qqComplementValues.countryCode ===
                    team?.homeAddress?.countryCode &&
                qqComplementValues.state === team?.homeAddress?.state
            )) ||
        !user ||
        !team;

    return (
        <Wrapper data-testid={`${prefix}-form`} data-cy={`${prefix}-form`}>
            <Header>
                <Heading size={HeadingSize.MEDIUM}>{title}</Heading>
                <Button
                    type="button"
                    variant="danger"
                    size="small"
                    onClick={() => {
                        reset({ ...getValues(), [prefix]: undefined });
                        if (contactOption) {
                            setContactOption(null);
                        }
                        /*
                            We reset the QQ values to their initial values,
                            if present, since they are read-only.
                        */
                        if (qqComplementValues) {
                            setValue(
                                `${prefix}.postalCode`,
                                qqComplementValues.postalCode
                            );
                            setValue(`${prefix}.city`, qqComplementValues.city);

                            setValue(
                                `${prefix}.countryCode`,
                                qqComplementValues.countryCode
                            );
                            setValue(
                                `${prefix}.state`,
                                qqComplementValues.state
                            );
                            setValue(
                                `${prefix}.isPrivate`,
                                qqComplementValues.isPrivate
                            );
                        } else if (countryOverride) {
                            setValue(`${prefix}.countryCode`, countryOverride);
                        } else {
                            setValue(
                                `${prefix}.countryCode`,
                                team?.systemCountry
                            );
                        }
                    }}
                >
                    {t('button.clear')}
                </Button>
            </Header>
            <AddressBookSearch
                value={contactOption}
                onSelect={setContactOption}
                filters={
                    qqComplementValues
                        ? {
                              countryCode: qqComplementValues.countryCode,
                              postalCode: qqComplementValues.postalCode,
                              state: qqComplementValues.state || '',
                              includePrivate: qqComplementValues.isPrivate,
                          }
                        : {
                              includePrivate: !hideIsPrivate,
                              countryCode: countryOverride,
                          }
                }
            />

            <Button
                disabled={disableFillHomeAddress}
                type="button"
                variant="primary"
                size="small"
                onClick={fillHomeAddress}
            >
                {t('button.useHomeAddress')}
            </Button>
            <Controller
                control={control}
                name={`${prefix}.isPrivate`}
                defaultValue={qqComplementValues?.isPrivate || false}
                render={({ onChange, value }) =>
                    !hideIsPrivate ? (
                        <ToggleWrapper interactive={!qqComplementValues}>
                            <label htmlFor={`${prefix}.isPrivateToggle`}>
                                <Text size={TextSize.MEDIUM}>
                                    {t('address:sendToPrivate')}
                                </Text>
                            </label>
                            <Switch
                                disabled={!!qqComplementValues}
                                checked={value}
                                onValueChange={(updatedValue) => {
                                    if (updatedValue) {
                                        const name = getValues(
                                            `${prefix}.contactName`
                                        );
                                        setValue(`${prefix}.name`, name);
                                        setValue(`${prefix}.contactName`, '');
                                    } else {
                                        setValue(`${prefix}.name`, '');
                                    }

                                    onChange(updatedValue);
                                }}
                                id={`${prefix}.isPrivateToggle`}
                            />
                        </ToggleWrapper>
                    ) : (
                        <></>
                    )
                }
            />
            <InputGrid>
                <Controller
                    control={control}
                    name={`${prefix}.name`}
                    rules={{
                        required: t('errors.required').toString(),
                        validate: (value) =>
                            value.length <= 35 ||
                            t('address:businessNameError').toString(),
                    }}
                    defaultValue=""
                    onFocus={() => {
                        scrollToAndFocus(nameInputRef);
                    }}
                    render={({ onChange, value }) => (
                        <>
                            <TwoColumnInput
                                ref={nameInputRef}
                                type="text"
                                value={value}
                                data-testid={`${prefix}-name`}
                                maxLength={35}
                                id={`${prefix}-name`}
                                label={
                                    isPrivate
                                        ? t('address:contactName')
                                        : t('address:businessName')
                                }
                                onChange={onChange}
                                error={!!errors[prefix]?.name}
                                errorMessage={errors[prefix]?.name?.message}
                                required
                            />
                            {!hideIsPrivate && !isPrivate && (
                                <TwoColumnNotification variant="note">
                                    <Text>
                                        {t('address:businessNameMessage')}
                                    </Text>
                                </TwoColumnNotification>
                            )}
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name={`${prefix}.addressLine1`}
                    rules={{
                        required: t('errors.required').toString(),
                    }}
                    defaultValue=""
                    onFocus={() => {
                        scrollToAndFocus(addressLine1InputRef);
                    }}
                    render={({ onChange, value }) => (
                        <TwoColumnInput
                            value={value}
                            onChange={onChange}
                            type="text"
                            id={`${prefix}.addressLine1`}
                            label={t('address:addressLine1WithBoxMessage')}
                            ref={addressLine1InputRef}
                            placeholder=""
                            error={!!errors[prefix]?.addressLine1}
                            errorMessage={errors[prefix]?.addressLine1?.message}
                            required
                        />
                    )}
                />

                <AdditionalAddressLines prefix={prefix} />

                <Controller
                    control={control}
                    name={`${prefix}.postalCode`}
                    defaultValue={qqComplementValues?.postalCode || ''}
                    onFocus={() => {
                        scrollToAndFocus(postalCodeInputRef);
                    }}
                    render={({ onChange, value }) => (
                        <FirstColumnInput
                            ref={postalCodeInputRef}
                            type="text"
                            id={`${prefix}-postalCode`}
                            readOnly={!!qqComplementValues}
                            label={t('address:postalCode')}
                            value={value}
                            onBlur={() => clearErrors(`${prefix}.postalCode`)}
                            onChange={onChange}
                            error={!!errors[prefix]?.postalCode}
                            errorMessage={errors[prefix]?.postalCode?.message}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`${prefix}.city`}
                    defaultValue={qqComplementValues?.city || ''}
                    rules={{
                        required: t('errors.required').toString(),
                    }}
                    onFocus={() => {
                        scrollToAndFocus(cityInputRef);
                    }}
                    render={({ onChange, value }) => (
                        <SecondColumnInput
                            ref={cityInputRef}
                            type="text"
                            id={`${prefix}-city`}
                            name={`${prefix}.city`}
                            value={value}
                            label={t('address:city')}
                            onChange={onChange}
                            error={!!errors[prefix]?.city}
                            errorMessage={errors[prefix]?.city?.message}
                            required
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`${prefix}.countryCode`}
                    defaultValue={
                        countryOverride ||
                        qqComplementValues?.countryCode ||
                        team?.systemCountry ||
                        getCountryFromDomain()
                    }
                    onFocus={() => {
                        scrollToSelect(countrySelectorRef);
                    }}
                    render={({ onChange, value }) => (
                        <TwoColumnCountrySelect
                            ref={countrySelectorRef}
                            inputId={`${prefix}-country`}
                            countryCode={value}
                            placeholder={t('address:countryPlaceholder')}
                            label={t('address:country')}
                            isDisabled={
                                !!countryOverride || !!qqComplementValues
                            }
                            onValueChange={(option) => {
                                onChange(option);
                                setValue(`${prefix}.state`, '');
                                clearErrors(`${prefix}.postalCode`);
                            }}
                            error={!!errors[prefix]?.countryCode}
                            errorMessage={errors[prefix]?.countryCode?.message}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={`${prefix}.state`}
                    rules={{
                        required: {
                            value: stateList.length > 0,
                            message: t('errors.required').toString(),
                        },
                    }}
                    defaultValue={qqComplementValues?.state || null}
                    onFocus={() => {
                        scrollToSelect(stateSelectorRef);
                    }}
                    render={({ onChange, value }) => (
                        <>
                            {stateList.length > 0 && (
                                <TwoColumnSelect<BaseOption>
                                    ref={stateSelectorRef}
                                    inputId={`${prefix}-state`}
                                    value={
                                        stateList?.find(
                                            (state) => state?.value === value
                                        ) || null
                                    }
                                    options={stateList}
                                    isDisabled={!!qqComplementValues}
                                    placeholder={t('address:statePlaceholder')}
                                    label={t('address:state')}
                                    onChange={(option) => {
                                        onChange(option?.value || '');
                                    }}
                                    error={!!errors[prefix]?.state}
                                    errorMessage={
                                        errors[prefix]?.state?.message
                                    }
                                />
                            )}
                        </>
                    )}
                />
            </InputGrid>
            <InputGrid>
                <Controller
                    control={control}
                    name={`${prefix}.contactName`}
                    rules={{
                        required: {
                            value: !isPrivate,
                            message: t('errors.required').toString(),
                        },
                        validate: (value) =>
                            value.length <= 35 ||
                            t('address:contactNameError').toString(),
                    }}
                    onFocus={() => {
                        scrollToAndFocus(contactNameInputRef);
                    }}
                    defaultValue=""
                    render={({ onChange, value }) => (
                        <>
                            {!isPrivate && (
                                <TwoColumnInput
                                    ref={contactNameInputRef}
                                    id={`${prefix}-contactname`}
                                    type="text"
                                    data-testid={`${prefix}-input-contactname`}
                                    maxLength={35}
                                    value={value}
                                    onChange={onChange}
                                    label={t('address:contactName')}
                                    error={!!errors[prefix]?.contactName}
                                    errorMessage={
                                        errors[prefix]?.contactName?.message
                                    }
                                    required={!isPrivate}
                                />
                            )}
                        </>
                    )}
                />

                <Controller
                    control={control}
                    name={`${prefix}.contactPhone`}
                    onFocus={() => {
                        scrollToAndFocus(contactPhoneInputRef);
                    }}
                    rules={{
                        required: t('errors.required').toString(),
                    }}
                    defaultValue=""
                    render={({ onChange, value }) => (
                        <TwoColumnInput
                            ref={contactPhoneInputRef}
                            type="text"
                            id={`${prefix}-contactphone`}
                            label={t('contact.phone')}
                            error={!!errors[prefix]?.contactPhone}
                            errorMessage={errors[prefix]?.contactPhone?.message}
                            value={value}
                            onChange={onChange}
                            required
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={`${prefix}.contactEmail`}
                    onFocus={() => {
                        scrollToAndFocus(contactEmailInputRef);
                    }}
                    rules={{
                        required:
                            shipmentType === 'international'
                                ? t('errors.required').toString()
                                : '',
                    }}
                    defaultValue=""
                    render={({ onChange, value }) => (
                        <TwoColumnInput
                            ref={contactEmailInputRef}
                            type="text"
                            id={`${prefix}-contactemail`}
                            label={
                                // Fall back to emails being optional, since that is the most common case.
                                // Also for adding contacts etc (outside the booking flow), we don't want
                                // to force the user to enter an email.
                                shipmentType == 'international'
                                    ? t('contact.email')
                                    : t('contact.email_optional')
                            }
                            error={!!errors[prefix]?.contactEmail}
                            errorMessage={errors[prefix]?.contactEmail?.message}
                            value={value}
                            onChange={onChange}
                            required={shipmentType === 'international'}
                        />
                    )}
                />
            </InputGrid>
            <Controller
                control={control}
                name={`${prefix}.receiverPaysAccountNumber`}
                onFocus={() => {
                    scrollToAndFocus(receiverPaysInputRef);
                }}
                defaultValue={
                    qqComplementValues?.receiverPaysAccountNumber || ''
                }
                render={({ onChange, value }) => (
                    <>
                        {/* Adding the account number here in QQ complement results in a booking without receiver pays.
                    We disable it here until the main issue is solved. */}
                        {supportsReceiverPays(team) &&
                            prefix === 'toAddress' &&
                            !qqComplementValues &&
                            !isPrivate && (
                                <InputGrid>
                                    {newFeatureBadgeCutoff.diffNow()
                                        .milliseconds > 0 && (
                                        <Badge
                                            textType="caption"
                                            variant="brand"
                                        >
                                            <StarIcon />
                                            {t('receiverPays.newFeature')}
                                        </Badge>
                                    )}
                                    <TwoColumnToggle interactive>
                                        <label
                                            htmlFor={`${prefix}.receiverPaysToggle`}
                                        >
                                            <Text size={TextSize.MEDIUM}>
                                                {t('receiverPays.description')}
                                            </Text>
                                        </label>
                                        <InfoTooltipIcon data-tooltip-id="tooltip-receiver-pays" />
                                        <Tooltip
                                            id="tooltip-receiver-pays"
                                            title={t(
                                                'receiverPays.tooltip.title'
                                            )}
                                            link={{
                                                text: t(
                                                    'receiverPays.tooltip.linkText'
                                                ),
                                                href: t(
                                                    'receiverPays.tooltip.linkUrl'
                                                ),
                                            }}
                                        >
                                            {t('receiverPays.tooltip.content')}
                                        </Tooltip>
                                        <Switch
                                            id={`${prefix}.receiverPaysToggle`}
                                            checked={showReceiverPays}
                                            onValueChange={(v) =>
                                                setShowReceiverPays(v)
                                            }
                                        />
                                    </TwoColumnToggle>
                                    {showReceiverPays && (
                                        <>
                                            <TwoColumnInput
                                                ref={receiverPaysInputRef}
                                                type="text"
                                                id={`${prefix}-receiverPays`}
                                                label={t('receiverPays.title')}
                                                placeholder={t(
                                                    'receiverPays.placeholder'
                                                )}
                                                error={
                                                    !!errors[prefix]
                                                        ?.contactEmail
                                                }
                                                errorMessage={
                                                    errors[prefix]?.contactEmail
                                                        ?.message
                                                }
                                                value={value}
                                                onChange={onChange}
                                            />
                                            <StyledTip variant="tip">
                                                {t('receiverPays.info')}
                                            </StyledTip>
                                        </>
                                    )}
                                </InputGrid>
                            )}
                    </>
                )}
            />
            <Controller
                control={control}
                name={`${prefix}.saveAsContact`}
                defaultValue={false}
                render={({ onChange, value }) =>
                    !isEditing ? (
                        <ToggleWrapper interactive>
                            <label htmlFor={`${prefix}.saveAsContact`}>
                                <Text size={TextSize.MEDIUM}>
                                    {consignor === 'sender'
                                        ? t('button.saveSenderAddress')
                                        : t('button.saveReceiverAddress')}
                                </Text>
                            </label>
                            <Switch
                                id={`${prefix}.saveAsContact`}
                                checked={value}
                                onValueChange={onChange}
                            />
                        </ToggleWrapper>
                    ) : (
                        <></>
                    )
                }
            />
        </Wrapper>
    );
};

export default AddressForm;
