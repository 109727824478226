import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import {
    cancelSubscription,
    changeSubscriptionTier,
    reactivateSubscription,
} from 'api/subscriptions';
import { PriceSubscriptionTier, Tier } from 'api/subscriptions/types';
import { Button } from 'components/Buttons';
import RadioButton from 'components/FormElements/RadioButton';
import RadioButtonGroup from 'components/FormElements/RadioButtonGroup';
import ContentCard from 'components/Layout/ContentCard';
import { ModalHeader } from 'components/Modal';
import PaymentForm from 'components/PaymentForm';
import { PaymentForm as Form } from 'components/PaymentForm/types';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import {
    getActiveSubscription,
    getCurrentPriceTier,
} from 'constants/integrations';
import {
    getFreeTier,
    tierOrderByTier,
    trialDays,
    yearlyCosts,
} from 'constants/priceTiers';
import { trackPaymentButton } from 'external/analytics';
import { useFlags } from 'external/launchDarkly';
import { formatCost } from 'helpers/CurrencyHelper';
import DateHelper from 'helpers/DateHelper';
import {
    getReadableDate,
    getStripeErrorMessage,
} from 'helpers/TranslationHelper';
import { useTeam, useUser } from 'hooks/Queries';
import { NewSubscription, useSubscriptions } from 'hooks/Queries/subscriptions';
import useSubscriptionTrialInfo from 'hooks/usePriceTierTrial';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Features from './Features';
import {
    Content,
    Footer,
    InfoContent,
    InfoFooter,
    InfoHeading,
    InfoText,
    LeftAlignText,
} from './StyledPayment';

interface Props {
    tier: PriceSubscriptionTier;
    onClose: () => void;
}

type ChangeType = 'new' | 'upgrade' | 'downgrade' | 'reactivate';

const Payment = ({ tier, onClose }: Props) => {
    const stripe = useStripe();
    const queryClient = useQueryClient();
    const { subscriptions, subscribe: createNewSubscription } =
        useSubscriptions();
    const elements = useElements();
    const { t, i18n } = useTranslation('settings');
    const { user } = useUser();
    const { team } = useTeam();
    const { enableInvoiceSubscription } = useFlags();
    const [paymentMethod, setPaymentMethod] = useState<'invoice' | 'card'>(
        team?.systemCountry === 'SE' ? 'card' : 'invoice'
    );
    if (!team) {
        return null;
    }

    const freeTier = getFreeTier(team.systemCountry);
    const subscription = subscriptions?.priceTierSubscription;
    const products = subscriptions?.subscriptionProducts;
    const trialInfo = useSubscriptionTrialInfo();
    const hasUsedTrial = trialInfo?.status !== 'notUsed';
    const currentTier = getCurrentPriceTier(subscriptions, team) || freeTier;
    const canceled =
        subscription?.canceled ||
        !!getActiveSubscription(subscriptions?.subscriptions || [], currentTier)
            ?.canceledAt;
    // show card form if the user is on the free tier and the tier they want to select is not free
    const showCardForm =
        currentTier === freeTier &&
        tier !== freeTier &&
        paymentMethod === 'card' &&
        team.systemCountry === 'SE';

    const supportsMultiplePaymentMethods = team.systemCountry === 'SE';

    const currentTierOrder = tierOrderByTier[currentTier];
    const tierOrder = tierOrderByTier[tier];

    const methods = useForm<Form>();
    const {
        handleSubmit,
        clearErrors,
        setValue,
        control,
        formState: { isSubmitting },
    } = methods;
    const cardComplete = useWatch<boolean>({
        control,
        name: `cardComplete`,
    });

    useEffect(() => {
        return () => {
            clearErrors('cardComplete');
            setValue('cardComplete', undefined);
        };
        // The card field is reset when the component is unmounted, so we reset the form field.
    }, [clearErrors, setValue]);

    /**
     * The cost of upgrading from one tier to another. Assumes that the team is on a paid tier.
     */
    const upgradeCost = useMemo(() => {
        if (
            tierOrder <= currentTierOrder || // not an upgrade
            !subscription ||
            !subscription?.current?.end ||
            !subscription?.current?.start
        ) {
            return null;
        }
        const oldCost = yearlyCosts[currentTier][team.currency];
        const newCost = yearlyCosts[tier][team.currency];
        const yearlyDifference = newCost - oldCost;
        const partOfBillingPeriodLeft = subscription?.current?.end
            .diff(subscription?.current?.start)
            .as('years');

        return partOfBillingPeriodLeft * yearlyDifference;
    }, [currentTier, currentTierOrder, subscription, tier, tierOrder, team]);

    const reactivate = async () => {
        try {
            const subscriptionID = subscriptions?.subscriptions?.find((sub) =>
                sub.items.find((item) => item.product.code === currentTier)
            )?.id;
            await reactivateSubscription(
                (subscriptionID === undefined
                    ? currentTier
                    : undefined) as Tier,
                subscriptionID
            );
            onClose();
        } catch (err) {
            toast.error(t('settings:priceTier.tierError'), {
                autoClose: false,
            });
        }
    };
    const changeTier = async () => {
        if (!subscription) {
            return;
        }
        try {
            await changeSubscriptionTier(
                tier as Tier,
                team.currency,
                subscription.interval
            );
            onClose();
        } catch (err) {
            toast.error(t('settings:priceTier.tierError'), {
                autoClose: false,
            });
        }
    };
    const cancel = async () => {
        // This is just here to make sure that we have fetched all info before attempting to cancel.
        if (!subscription) {
            return;
        }
        const subscriptionID = subscriptions?.subscriptions?.find((sub) =>
            sub.items.find((item) => item.product.code === currentTier)
        )?.id;

        try {
            await cancelSubscription(
                team.currency,
                subscriptionID === undefined ? currentTier : undefined,
                subscription.interval,
                subscriptionID
            );
            toast.success(t('settings:priceTier.tierSuccess'));
            onClose();
        } catch (err) {
            toast.error(t('settings:priceTier.tierError'), {
                autoClose: false,
            });
        }
        return;
    };
    const subscribe = async (paymentForm?: Form) => {
        if (paymentForm) {
            trackPaymentButton({
                userName: paymentForm.name,
                countryCode: paymentForm.address.countryCode,
                city: paymentForm.address.city,
            });
        }

        if (isSubmitting || !user || !team) {
            return;
        }
        let cardElement: StripeCardElement | undefined | null = undefined;
        if (!stripe || !elements) {
            return;
        }

        if (showCardForm) {
            cardElement = elements.getElement(CardElement);
            if (!cardElement) {
                return;
            }
        }

        try {
            let payload: NewSubscription = {
                tier,
                interval: 'year',
            };
            if (paymentMethod === 'card') {
                payload = {
                    ...payload,
                    stripe,
                    name: paymentForm?.name,
                    address: paymentForm?.address,
                    email: paymentForm?.email,
                    cardElement,
                };
            } else if (paymentMethod === 'invoice') {
                payload = {
                    ...payload,
                    priceId: subscriptions?.subscriptionProducts
                        ?.find((p) => p.code === tier)
                        ?.prices?.find((p) => p.interval === 'year')?.id,
                };
            }
            const confirmResult = await createNewSubscription(payload);
            if (confirmResult?.error) {
                toast.error(getStripeErrorMessage(t, confirmResult.error), {
                    autoClose: false,
                });
                return;
            }

            toast.success(t('settings:priceTier.tierSuccess'));
            onClose();
        } catch (e) {
            toast.error(t('settings:priceTier.tierError'), {
                autoClose: false,
            });
        }
    };
    const hasPaidTier = currentTier !== freeTier;

    const onSubmit = async (paymentForm: Form) => {
        if (showCardForm && !paymentForm.cardComplete) {
            return;
        }
        if (tier === freeTier) {
            await cancel();
        } else if (tier === currentTier) {
            await reactivate();
        } else if (hasPaidTier) {
            await changeTier();
        } else if (showCardForm) {
            return subscribe(paymentForm);
        } else {
            return subscribe();
        }
        queryClient.invalidateQueries(['subscriptions']);
    };

    let title = t(`settings:buy.priceTier`);
    let change: ChangeType = 'new';
    if (currentTierOrder > tierOrder) {
        change = 'downgrade';
        title = t('settings:priceTier.downgrade');
    } else if (currentTierOrder < tierOrder) {
        change = 'upgrade';
        title = t('settings:priceTier.upgrade');
    }

    if (
        (currentTier === tier && canceled) ||
        (subscription?.next?.tier !== undefined &&
            tierOrder !== undefined &&
            tierOrderByTier[subscription.next.tier] < tierOrder)
    ) {
        change = 'reactivate';
        title = t('settings:priceTier.reactivate');
    }

    const translatedTier = t(`settings:tier.${tier}`);

    const trialEnds = getReadableDate(
        t,
        DateTime.now().plus({
            days: trialDays,
        })
    );

    const startDate = useMemo(() => {
        if (change === 'upgrade') {
            return t('settings:payment.planActiveFrom', {
                from: getReadableDate(t, DateTime.now()),
            });
        }
        if (change === 'downgrade' && subscription?.current.end) {
            return t('settings:payment.planActiveFrom', {
                from: getReadableDate(t, subscription?.current.end),
            });
        }
        return t('settings:payment.planActiveFrom', {
            from: trialEnds,
        });
    }, [change, tier, freeTier, subscription]);
    if (!team) {
        return null;
    }

    const cost = useMemo(
        () =>
            formatCost(
                yearlyCosts[tier][team.currency],
                i18n.language,
                team.currency
            ),

        [tier, i18n.language, team.currency]
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="payment-form">
            <ModalHeader title={title} />
            <Content>
                <ContentCard withBorder>
                    <InfoContent>
                        <InfoHeading>
                            <Heading size={HeadingSize.SMALL}>
                                {translatedTier}
                            </Heading>
                            {tier !== freeTier && !hasUsedTrial && (
                                <Heading size={HeadingSize.SMALL}>
                                    {t(`settings:priceTier.heading.startTrial`)}
                                </Heading>
                            )}
                        </InfoHeading>

                        <InfoText>
                            {subscription?.current && tier === freeTier && (
                                <Trans
                                    i18n={i18n}
                                    values={{
                                        tier: translatedTier,
                                        count: 0,
                                    }}
                                    i18nKey={
                                        team?.legacySendifyPlusExpiryDate &&
                                        DateHelper.isInPast(
                                            team.legacySendifyPlusExpiryDate
                                        )
                                            ? `settings:priceTier.freeTierDescriptionLegacy`
                                            : `settings:priceTier.freeTierDescription`
                                    }
                                />
                            )}
                            {change === 'new' && (
                                <Trans
                                    i18n={i18n}
                                    values={{
                                        tier: translatedTier,
                                        cost,
                                        date: trialEnds,
                                    }}
                                    i18nKey="settings:payment.tierNewDescription"
                                />
                            )}
                            {currentTier === tier && (
                                <Trans
                                    i18n={i18n}
                                    values={{
                                        tier: translatedTier,
                                        cost,
                                    }}
                                    i18nKey="settings:priceTier.tierReactivationDescription"
                                />
                            )}

                            {change === 'upgrade' &&
                                upgradeCost !== null &&
                                (hasUsedTrial ? (
                                    <Trans
                                        i18n={i18n}
                                        values={{
                                            tier: translatedTier,
                                            difference: formatCost(
                                                upgradeCost,
                                                i18n.language,
                                                team.currency
                                            ),
                                            cost,
                                        }}
                                        i18nKey="settings:priceTier.tierUpgradeDescription"
                                    />
                                ) : (
                                    <Trans
                                        i18n={i18n}
                                        values={{
                                            tier: translatedTier,
                                            cost,
                                        }}
                                        i18nKey="settings:priceTier.tierUpgradeTrialDescription"
                                    />
                                ))}
                            {change === 'upgrade' &&
                                upgradeCost !== null &&
                                !hasUsedTrial && (
                                    <Trans
                                        i18n={i18n}
                                        values={{
                                            tier: translatedTier,
                                            cost,
                                        }}
                                        i18nKey="settings:priceTier.tierUpgradeTrialDescription"
                                    />
                                )}

                            {change === 'downgrade' && tier !== freeTier && (
                                <Trans
                                    i18n={i18n}
                                    values={{
                                        tier: translatedTier,
                                        cost,
                                    }}
                                    i18nKey="settings:priceTier.tierDowngradeDescription"
                                />
                            )}
                        </InfoText>
                        <Features tier={tier} />
                    </InfoContent>
                    {paymentMethod === 'card' ? (
                        <InfoFooter>
                            {hasUsedTrial ? (
                                <Text size={TextSize.MEDIUM}>
                                    {t('settings:payment.amount')}
                                </Text>
                            ) : (
                                <Text size={TextSize.MEDIUM}>
                                    {t('settings:payment.amountAfterTrial')}
                                </Text>
                            )}
                            <LeftAlignText size={TextSize.MEDIUM}>
                                {cost}
                            </LeftAlignText>
                            <Text>{startDate}</Text>
                            <LeftAlignText>
                                {t('settings:payment.exclVat')}
                            </LeftAlignText>
                        </InfoFooter>
                    ) : (
                        <>
                            {products
                                ?.filter((p) => p.code === tier)
                                ?.map((p) => (
                                    <>
                                        {p?.prices?.map((pr) => (
                                            <InfoFooter key={pr.id}>
                                                {hasUsedTrial ? (
                                                    <Text
                                                        size={TextSize.MEDIUM}
                                                    >
                                                        {t(
                                                            'settings:payment.amount'
                                                        )}
                                                    </Text>
                                                ) : (
                                                    <Text
                                                        size={TextSize.MEDIUM}
                                                    >
                                                        {t(
                                                            'settings:payment.amountAfterTrial'
                                                        )}
                                                    </Text>
                                                )}
                                                <LeftAlignText
                                                    size={TextSize.MEDIUM}
                                                >
                                                    {formatCost(
                                                        change ===
                                                            'downgrade' ||
                                                            change ===
                                                                'reactivate'
                                                            ? 0
                                                            : pr.price,
                                                        i18n.language,
                                                        team.currency
                                                    )}
                                                </LeftAlignText>
                                                <Text>{startDate}</Text>
                                                <LeftAlignText>
                                                    {t(
                                                        'settings:payment.exclVat'
                                                    )}
                                                </LeftAlignText>
                                            </InfoFooter>
                                        ))}
                                    </>
                                ))}
                        </>
                    )}
                </ContentCard>
                {enableInvoiceSubscription &&
                    supportsMultiplePaymentMethods &&
                    (change === 'new' || change === 'upgrade') && (
                        <RadioButtonGroup
                            horizontal
                            value={paymentMethod}
                            onChange={(v) => setPaymentMethod(v)}
                        >
                            <RadioButton
                                label={t('settings:payment.card')}
                                value="card"
                            />
                            <RadioButton
                                value="invoice"
                                label={t('settings:payment.invoice')}
                            />
                        </RadioButtonGroup>
                    )}
                {showCardForm && (
                    <FormProvider {...methods}>
                        <PaymentForm />
                    </FormProvider>
                )}
            </Content>
            <Footer>
                <Button
                    size="large"
                    variant="secondary"
                    type="button"
                    onClick={onClose}
                >
                    {t('button.cancel')}
                </Button>
                {!showCardForm ? (
                    <Button size="large" type="submit" loading={isSubmitting}>
                        {t('settings:payment.confirm')}
                    </Button>
                ) : (
                    <Button
                        size="large"
                        type="submit"
                        form="payment-form"
                        disabled={!cardComplete}
                        loading={!stripe || isSubmitting}
                    >
                        {t('settings:payment.confirm')}
                    </Button>
                )}
            </Footer>
        </form>
    );
};

export default Payment;
