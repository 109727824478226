import Modal from 'components/Modal';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import { getPlusTier } from 'constants/priceTiers';
import Payment from 'containers/Settings/components/PriceTierPayment';
import { getFrontendLanguage } from 'helpers/LanguageHelper';
import { getSendifyPlusHelpCenterLink } from 'helpers/LinkHelper';
import { useTeam } from 'hooks/Queries';
import { usePersistentState } from 'hooks/usePersistentState';
import useSubscriptionTrialInfo from 'hooks/usePriceTierTrial';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import InfoBanner, { useTrialBanner } from './InfoBanner';
import { InfoText } from './StyledInfoBanner';

const StyledReactLink = styled(Link)`
    color: black;
    text-decoration: underline;
`;

const SendifyPlusLink = styled.a`
    background-color: ${({ theme }) => theme.colors.cardboardBrown300};
    color: black;
    padding: ${({ theme }) => theme.space.s1};
    text-decoration: underline;
`;

export default function PlusTrialBanner() {
    const { t } = useTranslation('dashboard');
    const { showBanner, closeBanner } = useTrialBanner('trialBanner');

    const { team } = useTeam();
    const trialInfo = useSubscriptionTrialInfo();

    const [showModal, setShowModal] = useState(false);

    const [hasSeenModal, setHasSeenModal] = usePersistentState(
        'seenPaymentModal',
        false
    );

    // Show the payment modal if the trial has ended. German teams are not yet
    // onboarded on this flow, so show it for Swedish teams only via LD.
    useEffect(() => {
        if (trialInfo?.status === 'ended' && !hasSeenModal) {
            setShowModal(true);
        }
    }, [trialInfo, team]);

    const onClose = () => {
        // Assume that they have seen the payment modal only when they actively
        // close it. This is to ensure that we show the modal again if they
        // navigate away from the page.
        setHasSeenModal(true);
        setShowModal(false);
    };

    const lang = getFrontendLanguage();
    if (!lang || !trialInfo || !team) {
        return null;
    }

    const allowedStatus = ['started', 'ending', 'ended'];

    if (!allowedStatus.includes(trialInfo.status)) {
        return null;
    }

    const tier = getPlusTier(team?.systemCountry);

    return (
        <>
            <InfoBanner show={showBanner} onClose={closeBanner}>
                <Heading size={HeadingSize.XSMALL}>
                    {t(`dashboard.trialBanner.${trialInfo.status}.title`)}
                </Heading>

                <InfoText>
                    <Trans
                        t={t}
                        i18nKey={`dashboard.trialBanner.${trialInfo.status}.subtitle`}
                    >
                        <SendifyPlusLink
                            href={getSendifyPlusHelpCenterLink(lang)}
                            id="trial-banner-helpcenter"
                        />

                        <StyledReactLink
                            to={{
                                pathname: '/settings/price-tier',
                                search: `?tier=${tier}`,
                            }}
                            id="trial-banner-upgrade"
                        />
                    </Trans>
                </InfoText>
            </InfoBanner>

            {!hasSeenModal ? (
                <Modal showModal={showModal} onClose={onClose} width="600px">
                    <Payment tier={tier} onClose={onClose} />
                </Modal>
            ) : null}
        </>
    );
}
