import Text from 'components/Typography/Text';
import styled, { css } from 'styled-components/macro';

// Hide the checkbox, but not for screen readers: https://polished.js.org/docs/#hidevisually.
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    top: 0;
    left: 0;
`;

const size = '20px';
const padding = '8px';

export const StyledCheckbox = styled.div<{
    checked: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
}>`
    ${({ theme, disabled, checked, indeterminate }) => {
        const { colors, space } = theme;
        const borderWidth = '1px';

        let borderColor = colors.grey100;
        let backgroundColor = colors.white;
        let iconColor = colors.white;
        if (checked || indeterminate) {
            borderColor = colors.black;
            backgroundColor = colors.black;
        }
        if (disabled) {
            borderColor = colors.grey200;
            backgroundColor = colors.grey400;
            iconColor = colors.grey100;
        }

        // Create an icon.
        const iconThickness = '2px';
        let icon = css``;
        if (indeterminate) {
            // Create a horizontal line.
            icon = css`
                background-color: ${iconColor};
                content: '';
                display: block;
                height: ${iconThickness};
                position: absolute;
                width: calc(${iconThickness} * 6);
            `;
        } else if (checked) {
            // Create a checkmark.
            const width = `calc(${iconThickness} * 2)`;
            const height = `calc(${iconThickness} * 5)`;
            icon = css`
                border: solid ${iconColor};
                border-width: 0 ${iconThickness} ${iconThickness} 0;
                content: '';
                display: block;
                height: ${height};
                // Remove some height from the top so that the top of the checkbox is as far
                // from the top of the container as the bottom is from the bottom of the
                // container.
                margin-top: calc(${iconThickness} - ${width});
                position: absolute;
                transform: rotate(45deg);
                width: ${width};
            `;
        }

        return css`
            background-color: ${backgroundColor};
            border: ${borderWidth} solid ${borderColor};
            display: inline-grid;
            height: ${size};
            left: ${space.s2};
            place-items: center;
            position: absolute;
            top: ${space.s2};
            width: 20px;
            // When the "real" checkbox is focused, we want to highlight the visible one.
            ${HiddenCheckbox}:focus + & {
                box-shadow: 0 0 0 1px ${colors.white},
                    0 0 0 2px ${colors.blue200};
            }
            ${HiddenCheckbox}:focus:not(:focus-visible)  + & {
                box-shadow: none;
            }
            :after {
                ${icon}
            }
        `;
    }}
`;

export const Wrapper = styled.label<{ disabled?: boolean }>`
    align-items: center;
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
    display: flex;
    min-height: calc(${size} + ${padding} * 2);
    // Offset the padding, so we can keep the clickable area but not
    // interrupt the layout.
    margin-left: calc(${padding} * -1);
    min-width: calc(${size} + ${padding} * 2);
    // Add some clickable area outside the checkbox. The total clickable
    // area is the same size as a small Button.
    padding: ${padding};
    position: relative;
    width: fit-content;
    box-sizing: border-box;
    ${({ theme, disabled }) => {
        if (disabled) {
            return '';
        }
        /*
        We are unable to have a conditional hover effect if we do it the
        other way around (i.e. have this block in StyledCheckbox, to be
        activated on Wrapper hover. We instead target the StyledCheckbox
        from here.
        */
        return css`
            &:hover ${StyledCheckbox} {
                border: 1px solid ${theme.colors.black};
            }
        `;
    }}
`;

export const Label = styled(Text)`
    padding-left: calc(${size} + 0.5em);
`;
