import { Help, Settings } from 'assets/icons';
import {
    Notification,
    NotificationActive,
    ShoppingCart,
} from 'assets/icons/material';
import Badge from 'components/Badge';
import { IconButton } from 'components/Buttons';
import { trackCartOpened, trackCreateNewShipment } from 'external/analytics';
import { selectorId, useBeamerContext } from 'external/beamer';
import { useFlags } from 'external/launchDarkly';
import { getHelpCenterLink } from 'helpers/LinkHelper';
import UserDataHelper from 'helpers/UserDataHelper';
import { useCartContext } from 'hooks/Cart';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useHistory, useLocation } from 'react-router-dom';
import styled, { DefaultTheme, ThemeContext } from 'styled-components/macro';

import { useFavorites } from '../../hooks/Queries/useFavorites';
import { useSupportedIntegrations } from '../../hooks/useSupportedIntegrations';
import Dropdown from '../Dropdown/Dropdown';
import DEPRECATEDFlexContainer from '../Layout/DEPRECATEDFlexContainer';
import LanguageSwitch from '../Layout/LanguageSwitch/LanguageSwitch';
import DEPRECATEDSpacer, { SpaceSize } from '../Spacings/DEPRECATEDSpacer';
import Tooltip from '../Tooltip';
import NavigationItem from './NavigationItem';
import UserMenu from './UserMenu';

const DesktopMenuContainer = styled.div`
    width: 100%;
`;

interface StyledLinkProps extends LinkProps {
    smallerRightPadding?: boolean;
    theme: DefaultTheme;
}

const StyledLink = styled(Link)<StyledLinkProps>`
    padding: ${({ theme, smallerRightPadding }) =>
        smallerRightPadding
            ? `${theme.space.s4} ${theme.space.s1} ${theme.space.s4} ${theme.space.s5}`
            : `${theme.space.s4} ${theme.space.s5}`};
`;

interface StyledCTADivProps {
    theme: DefaultTheme;
}

const StyledCTADiv = styled.div<StyledCTADivProps>`
    display: flex;
    padding-right: ${({ theme }) => `${theme.space.s4}`};
`;

interface DesktopMenuProps {
    enableOnboarding: boolean;
}

const DesktopMenu = ({ enableOnboarding }: DesktopMenuProps) => {
    const { t } = useTranslation();
    const { showNavigation } = UserDataHelper;
    const {
        importNewBadge,
        beamer: showBeamer,
        showAnalyticsNavigationOption,
        showAnalyticsBetaBadge,
        bookAShipmentAsACta,
    } = useFlags();
    const history = useHistory();
    const { setCartVisibility, cartState } = useCartContext();
    const { consignments, isVisible } = cartState;
    const beamer = useBeamerContext();
    const { notificationNumber } = beamer;

    const { colors } = useContext(ThemeContext);
    const systems = useSupportedIntegrations();
    const enabled = systems.some((s) => s.status !== 'DISABLED');

    const showImportNewBadge =
        importNewBadge && localStorage.getItem('hasSeenImport') === null;
    const location = useLocation();

    const { data: favorites } = useFavorites();
    const favoriteItems = favorites?.map((favorite) => {
        return {
            id: favorite.id.toString(),
            title: favorite.favoriteName,
            icon: 'favorite_star',
            toggleItem: () => {
                trackCreateNewShipment(
                    'dropdown',
                    favorites?.length || 0,
                    { book_shipment_as_a_cta: bookAShipmentAsACta },
                    favorite.id
                );
                history.push('/newshipment', {
                    consignment: JSON.stringify(favorite.favorite),
                    favoriteId: favorite.id,
                });
            },
        };
    });
    favoriteItems?.push({
        icon: '',
        id: 'fromScratch',
        title: t('menu.fromScratch'),
        toggleItem: () => {
            trackCreateNewShipment('dropdown', favorites?.length || 0, {
                book_shipment_as_a_cta: bookAShipmentAsACta,
            });
            createNewShipment();
        },
    });

    const handleShipmentTitleClick = () => {
        trackCreateNewShipment('navbar', favorites?.length || 0, {
            book_shipment_as_a_cta: bookAShipmentAsACta,
        });
        createNewShipment();
    };

    const createNewShipment = () =>
        history.push('/newshipment', {
            consignment: '',
            favoriteId: undefined,
        });

    const getFavoriteItems = () => {
        return !favoriteItems || favoriteItems.length < 2 ? [] : favoriteItems;
    };

    return (
        <DesktopMenuContainer data-cy="desktop-menu">
            <DEPRECATEDFlexContainer
                fullWidth
                direction="row"
                justifyContent="space-between"
            >
                <DEPRECATEDSpacer marginLeft={SpaceSize.X_LARGE}>
                    <DEPRECATEDFlexContainer direction="row" fullHeight>
                        {bookAShipmentAsACta && (
                            <StyledCTADiv>
                                <Dropdown
                                    style={{
                                        whiteSpace: 'nowrap',
                                        margin: 'auto 0',
                                        color: 'white',
                                        background: 'black',
                                        height: '32px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                    }}
                                    title={t('menu.bookAShipment')}
                                    toggleIcon="assets/icons/misc/icons/ionic-arrow-right.svg"
                                    list={getFavoriteItems()}
                                    dtype="button"
                                    withBorder={false}
                                    hoverToOpen={true}
                                    onClick={handleShipmentTitleClick}
                                />
                            </StyledCTADiv>
                        )}
                        <NavigationItem
                            state={
                                location.pathname === '/dashboard' ||
                                location.pathname === '/'
                                    ? 'active'
                                    : 'normal'
                            }
                        >
                            <StyledLink to="/dashboard">
                                {t('menu.dashboard')}
                            </StyledLink>
                        </NavigationItem>
                        {!bookAShipmentAsACta && (
                            <NavigationItem
                                state={
                                    location.pathname === '/newshipment'
                                        ? 'active'
                                        : 'normal'
                                }
                            >
                                {favoriteItems && favoriteItems.length > 1 ? (
                                    <Dropdown
                                        style={{ padding: '8px 20px' }}
                                        title={t('menu.newShipment')}
                                        toggleIcon="assets/icons/misc/icons/ionic-arrow-right.svg"
                                        list={favoriteItems}
                                        dtype="button"
                                        withBorder={false}
                                        hoverToOpen={true}
                                        onTitleClick={handleShipmentTitleClick}
                                    />
                                ) : (
                                    <StyledLink to="/newshipment">
                                        {t('menu.newShipment')}
                                    </StyledLink>
                                )}
                            </NavigationItem>
                        )}
                        {enabled && (
                            <NavigationItem
                                state={
                                    location.pathname === '/import'
                                        ? 'active'
                                        : 'normal'
                                }
                            >
                                <StyledLink to="/import">
                                    {t('menu.import')}
                                </StyledLink>
                                {showImportNewBadge && (
                                    <Badge textType="text" variant="brand">
                                        {t('menu.new')}
                                    </Badge>
                                )}
                            </NavigationItem>
                        )}
                        <NavigationItem
                            state={
                                location.pathname === '/orders'
                                    ? 'active'
                                    : 'normal'
                            }
                        >
                            <StyledLink to="/orders">
                                {t('menu.orders')}
                            </StyledLink>
                        </NavigationItem>
                        {showAnalyticsNavigationOption && (
                            <NavigationItem
                                state={
                                    location.pathname === '/analytics'
                                        ? 'active'
                                        : 'normal'
                                }
                            >
                                <StyledLink
                                    smallerRightPadding={showAnalyticsBetaBadge}
                                    to="/analytics"
                                >
                                    {t('menu.insights')}
                                </StyledLink>
                                {showAnalyticsBetaBadge && (
                                    <Badge textType="text" variant="brand">
                                        {t('menu.beta')}
                                    </Badge>
                                )}
                            </NavigationItem>
                        )}
                        {enableOnboarding && (
                            <NavigationItem
                                state={
                                    location.pathname === '/onboarding'
                                        ? 'active'
                                        : 'normal'
                                }
                            >
                                <StyledLink to="/onboarding">
                                    {t('menu.onboarding')}
                                </StyledLink>
                            </NavigationItem>
                        )}
                        {showNavigation && (
                            <NavigationItem
                                state={
                                    location.pathname === '/admin'
                                        ? 'active'
                                        : 'normal'
                                }
                            >
                                <StyledLink to="/admin">
                                    {t('menu.admin')}
                                </StyledLink>
                            </NavigationItem>
                        )}
                    </DEPRECATEDFlexContainer>
                </DEPRECATEDSpacer>
                <DEPRECATEDFlexContainer direction="row">
                    <NavigationItem state="normal">
                        <LanguageSwitch />
                    </NavigationItem>
                    {showBeamer && (
                        <NavigationItem state="normal">
                            <Tooltip
                                className="small text"
                                id="beamer"
                                place="bottom"
                            >
                                {t('menu.news')}
                            </Tooltip>
                            <IconButton
                                aria-label={t('menu.notificationCenter')}
                                id={selectorId}
                                data-tooltip-id="beamer"
                                icon={
                                    notificationNumber > 0 ? (
                                        <NotificationActive />
                                    ) : (
                                        <Notification />
                                    )
                                }
                                badge={
                                    notificationNumber > 0
                                        ? notificationNumber
                                        : undefined
                                }
                                badgeColor={colors.red200}
                            />
                        </NavigationItem>
                    )}
                    <Tooltip className="small text" id="cart" place="bottom">
                        {t('cart.heading')}
                    </Tooltip>
                    <NavigationItem state="normal">
                        <IconButton
                            data-cy="cart-button-toggle"
                            data-tooltip-id="cart"
                            icon={<ShoppingCart />}
                            badge={
                                consignments.length > 0
                                    ? consignments.length
                                    : undefined
                            }
                            onClick={() => {
                                trackCartOpened('navbar');
                                setCartVisibility(!isVisible);
                            }}
                        />
                    </NavigationItem>
                    <Tooltip
                        className="small text"
                        id="settings"
                        place="bottom"
                    >
                        {t('menu.settings')}
                    </Tooltip>
                    <NavigationItem
                        state={
                            location.pathname === '/settings'
                                ? 'active'
                                : 'normal'
                        }
                    >
                        <Link
                            to="/settings"
                            id="tooltip-activate-text"
                            data-tip
                            data-tooltip-id="settings"
                        >
                            <IconButton icon={<Settings />} />
                        </Link>
                    </NavigationItem>
                    <Tooltip
                        className="small text"
                        id="help-centre"
                        place="bottom"
                    >
                        {t('menu.helpCentre')}
                    </Tooltip>
                    <NavigationItem state="normal">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            data-tip
                            data-tooltip-id="help-centre"
                            href={getHelpCenterLink()}
                        >
                            <IconButton icon={<Help />} />
                        </a>
                    </NavigationItem>
                    <Tooltip
                        className="small text"
                        id="user-menu"
                        place="bottom"
                    >
                        {t('menu.account')}
                    </Tooltip>
                    <NavigationItem state="normal">
                        <a data-tip data-tooltip-id="user-menu">
                            <UserMenu />
                        </a>
                    </NavigationItem>
                </DEPRECATEDFlexContainer>
            </DEPRECATEDFlexContainer>
        </DesktopMenuContainer>
    );
};

export default DesktopMenu;
