import { put } from 'api';
import { toBackendConsignmentRequest } from 'api/consignments/mapper';
import { ProductCode, Relationship } from 'api/consignments/types';
import { QuickQuotationConsignment } from 'api/qq/types';
import { goDomain } from 'config';
import { Currency } from 'types/UserData';

export const addonCodes = [
    'sendify_insurance',
    'sendify_insurance_service_fee',
    'carrier_insurance',
    'notification',
    'delivery_instructions',
    'pickup_instructions',
    'pickup_notification',
    'delivery_notice',
    'tailgate_lift_unloading',
    'tailgate_lift_loading',
    'definite_unloading',
    'definite_loading',
    'guaranteed_delivery_time',
    'delivery_without_proof',
    'receiver_pays',
    'without_pickup',
    'green_shipping',
    'dangerous_goods_limited_quantity',
];

export type AddonCode = typeof addonCodes[number];

type Payload = Partial<{
    declaredValue: number;
    declaredCurrency: Currency;
    definiteLoadingTime: string; // In HH:MM
    definiteUnloadingTime: string; // In HH:MM
    instructions: string;
    timeOfDay: string; // In HH:MM
    receiverPaysAccountNumber: string;
}>;

export interface AddonInput {
    code: AddonCode;
    payload?: Payload;
}

export interface Recommendation {
    productCode: ProductCode;
    price: number;
}

export interface Addon {
    code: AddonCode;
    price: number | null;
    currency: Currency;
    included: boolean;
    payload: Payload;
    relationship: Relationship[];
}

export interface AddonResponse {
    [quoteId: number]: {
        addons: Addon[];
        unsupportedAddonCodes: AddonCode[];
        unsupportedAddonInput: AddonInput[];
    };
}

/**
 * Sets the addons on the supplied quotes.
 * @returns A map with all the quote IDs sent in, along with the
 * addons connected to the quote and the which of the new codes that
 * weren't supported.
 */
export const setAddons = async (
    quoteIDs: number[],
    consignment: QuickQuotationConsignment,
    addons: AddonInput[]
) => {
    const resp = await put<AddonResponse>(`${goDomain}v2/search/addons`, {
        quoteIDs,
        consignment: toBackendConsignmentRequest(consignment),
        addons,
    });
    if (resp.parsedBody === undefined) {
        throw new Error('Failed to set addons');
    }
    return resp.parsedBody;
};
