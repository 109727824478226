import { Close } from 'assets/icons/material';
import { IconButton } from 'components/Buttons';
import { ReactNode, useEffect, useState } from 'react';

import { Content, InfoWrapper } from './StyledInfoBanner';

export const useTrialBanner = (name: string) => {
    const [showBanner, setShowBanner] = useState(false);

    const closeBanner = () => {
        setShowBanner(false);
        // we save it to local storage to not have the user see the banner each page load
        localStorage.setItem(name, 'false');
    };

    useEffect(() => {
        if (name) {
            // check if the user has dismissed the banner previously
            const storageItem = localStorage.getItem(name);
            if (storageItem === null) {
                setShowBanner(true);
            } else {
                setShowBanner(JSON.parse(storageItem));
            }
        }
    }, [name]);

    return { showBanner, closeBanner };
};

interface Props {
    onClose: () => void;
    children?: ReactNode;
    show: boolean;
}

const InfoBanner = ({ onClose, show, children }: Props) => {
    if (!show) {
        return null;
    }

    return (
        <InfoWrapper>
            <Content>
                {children}
                <IconButton icon={<Close />} onClick={onClose} />
            </Content>
        </InfoWrapper>
    );
};

export default InfoBanner;
