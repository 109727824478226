/* eslint-disable react/jsx-props-no-spreading */
import { Consignment } from 'api/consignments/types';
import { Button } from 'components/Buttons';
import Input from 'components/FormElements/Input';
import Switch from 'components/FormElements/Switch';
import GoodsForm from 'components/GoodsForm/GoodsForm';
import { HeaderWrapper } from 'components/GoodsForm/StyledGoodsForm';
import ContentCard from 'components/Layout/ContentCard';
import AddressForm from 'components/NewShipmentForm/components/AddressForm/AddressForm';
import Footer from 'components/NewShipmentForm/components/Footer/Footer';
import {
    backendErrorsToFrontend,
    consignmentToForm,
} from 'components/NewShipmentForm/mapper';
import { Form, FormPackage } from 'components/NewShipmentForm/types';
import {
    AddressCard,
    CardContentWrapper,
    FormContentWrapper,
    NewShipmentFormContainer,
    SliderContentContainer,
    StyledShipmentFormSlider,
    ViaToggleWrapper,
} from 'components/ShipmentFormSlider/StyledShipmentFormSlider';
import Slider from 'components/Slider';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import { externalReferenceRegex } from 'constants/regex';
import { trackAddUnit } from 'external/analytics';
import DateHelper from 'helpers/DateHelper';
import useHolidays from 'hooks/Queries/useHolidays';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import {
    Controller,
    FormProvider,
    UseFormMethods,
    useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components/macro';
import { BackendErrors } from 'types';

interface Props {
    consignment: Consignment;
    isEditing: boolean;
    moveToCart: boolean;
    submit: (form: Form) => Promise<BackendErrors | undefined>;
    methods: UseFormMethods;
    show: boolean;
    onClose: () => void;
}

const ShipmentFormSlider = ({
    consignment,
    isEditing,
    moveToCart,
    show,
    onClose,
    submit,
    methods,
}: Props) => {
    const { t } = useTranslation(['goods', 'address']);

    const [showViaFromAddress, setShowViaFromAddress] = useState(false);
    const [showViaToAddress, setShowViaToAddress] = useState(false);

    const {
        colors: { grey500 },
    } = useContext(ThemeContext);

    const {
        control,
        errors,
        formState,
        handleSubmit,
        register,
        reset,
        setError,
        watch,
    } = methods;

    const { isSubmitting } = formState;

    const { fields, append, remove } = useFieldArray<FormPackage>({
        control,
        name: 'packages',
    });

    const setErrorsFromBackend = (
        form: Form,
        backendErrors: BackendErrors
    ): void => {
        const mappedErrors = backendErrorsToFrontend(
            backendErrors,
            form?.packages?.length || 0
        );

        Object.keys(mappedErrors).forEach((key) => {
            const error = mappedErrors[key];
            if (error) {
                setError(key, {
                    message: error,
                    shouldFocus: true,
                });
            }
        });
    };
    const onSubmit = async (data: Form) => {
        const backendErrors = await submit(data);
        if (backendErrors) {
            setErrorsFromBackend(data, backendErrors);
        } else {
            onClose();
        }
    };

    useEffect(() => {
        if (consignment && show) {
            const form = consignmentToForm(consignment);
            if (!form.packages || form.packages.length === 0) {
                form.packages = [{}];
            }
            setShowViaFromAddress(!!form.fromViaAddress);
            setShowViaToAddress(!!form.toViaAddress);
            reset(form);
        }
    }, [reset, consignment, show]);

    const senderCountryCode = watch('fromAddress.countryCode');
    const senderState = watch('fromAddress.state');
    const alternativeSenderState = watch('fromViaAddress.state');
    // || undefined is to avoid null
    const holidays =
        useHolidays(
            senderCountryCode,
            alternativeSenderState || senderState || undefined // || undefined is to avoid null
        ).holidays || [];

    const defaultDate = DateHelper.getNextValidPickupDate(
        DateTime.now(),
        holidays
    );
    return (
        <Slider
            position="left"
            background={grey500}
            show={show}
            width="600px"
            onClose={onClose}
        >
            {show && (
                <SliderContentContainer>
                    <NewShipmentFormContainer>
                        <FormProvider {...methods}>
                            <StyledShipmentFormSlider
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <FormContentWrapper>
                                    <AddressCard
                                        hasVia={showViaFromAddress}
                                        withShadow
                                        withPadding
                                    >
                                        <AddressForm
                                            consignor="sender"
                                            hideIsPrivate
                                            prefix="fromAddress"
                                            title={t('address:sender')}
                                            isEditing={isEditing}
                                        />

                                        <ViaToggleWrapper>
                                            <label htmlFor="altsender">
                                                <Text size={TextSize.MEDIUM}>
                                                    {t(
                                                        'button.useAlternativeSenderAddress'
                                                    )}
                                                </Text>
                                            </label>
                                            <Switch
                                                checked={showViaFromAddress}
                                                onValueChange={
                                                    setShowViaFromAddress
                                                }
                                                id="altsender"
                                            />
                                        </ViaToggleWrapper>

                                        {showViaFromAddress && (
                                            <>
                                                <AddressForm
                                                    consignor="sender"
                                                    hideIsPrivate
                                                    isEditing={isEditing}
                                                    prefix="fromViaAddress"
                                                    title={t(
                                                        'address:alternativePickupAddress'
                                                    )}
                                                />
                                            </>
                                        )}
                                    </AddressCard>

                                    <AddressCard
                                        hasVia={showViaToAddress}
                                        withShadow
                                        withPadding
                                    >
                                        <AddressForm
                                            consignor="recipient"
                                            prefix="toAddress"
                                            isEditing={isEditing}
                                            title={t('address:receiver')}
                                        />
                                        <ViaToggleWrapper>
                                            <label htmlFor="altreceiver">
                                                <Text size={TextSize.MEDIUM}>
                                                    {t(
                                                        'button.useAlternativeReceiverAddress'
                                                    )}
                                                </Text>
                                            </label>

                                            <Switch
                                                checked={showViaToAddress}
                                                onValueChange={
                                                    setShowViaToAddress
                                                }
                                                data-cy="toggle-to-via-address"
                                                id="altreceiver"
                                            />
                                        </ViaToggleWrapper>
                                        {showViaToAddress && (
                                            <>
                                                <AddressForm
                                                    consignor="recipient"
                                                    prefix="toViaAddress"
                                                    isEditing={isEditing}
                                                    title={t(
                                                        'address:alternativeDeliveryAddress'
                                                    )}
                                                />
                                            </>
                                        )}
                                    </AddressCard>
                                </FormContentWrapper>

                                <FormContentWrapper>
                                    {fields.map((pack, index) => {
                                        return (
                                            <ContentCard
                                                withPadding
                                                withShadow
                                                key={pack.id}
                                            >
                                                <HeaderWrapper>
                                                    <Heading
                                                        size={
                                                            HeadingSize.MEDIUM
                                                        }
                                                    >
                                                        {t('goods:title', {
                                                            count: index + 1,
                                                        })}
                                                    </Heading>
                                                    <Button
                                                        variant="secondary"
                                                        size="small"
                                                        type="button"
                                                        disabled={
                                                            fields.length === 1
                                                        }
                                                        onClick={() => {
                                                            if (remove)
                                                                remove(index);
                                                        }}
                                                    >
                                                        {t('button.removeUnit')}
                                                    </Button>
                                                </HeaderWrapper>
                                                <GoodsForm
                                                    formId="shipmentSlider"
                                                    index={index}
                                                    pack={pack}
                                                />
                                            </ContentCard>
                                        );
                                    })}
                                    <div>
                                        <ContentCard withPadding withShadow>
                                            <Button
                                                type="button"
                                                variant="secondary"
                                                fullWidth
                                                onClick={() => {
                                                    trackAddUnit(
                                                        'shipmentSlider',
                                                        fields.length
                                                    );
                                                    append({
                                                        type: 'PACKAGE',
                                                        quantity: 1,
                                                    });
                                                }}
                                            >
                                                {t('button.addPackage')}
                                            </Button>
                                        </ContentCard>
                                    </div>
                                    <div>
                                        <ContentCard withPadding withShadow>
                                            <CardContentWrapper>
                                                <Heading
                                                    size={HeadingSize.MEDIUM}
                                                >
                                                    {t(
                                                        'goods:otherInformation'
                                                    )}
                                                </Heading>
                                                <Input
                                                    name="externalReference"
                                                    ref={register({
                                                        pattern: {
                                                            value: externalReferenceRegex,
                                                            message: t(
                                                                'errors.invoiceReference'
                                                            ),
                                                        },
                                                    })}
                                                    label={t(
                                                        'goods:invoiceReference'
                                                    )}
                                                    error={
                                                        !!errors.externalReference
                                                    }
                                                    errorMessage={
                                                        errors.externalReference
                                                            ?.message
                                                    }
                                                />
                                            </CardContentWrapper>
                                        </ContentCard>
                                    </div>
                                </FormContentWrapper>
                                <Controller
                                    control={control}
                                    name="date"
                                    defaultValue={defaultDate}
                                    render={({ onChange, value }) => (
                                        <Footer
                                            isLoading={isSubmitting}
                                            isEditing={isEditing}
                                            moveToCart={moveToCart}
                                            onCancelEdit={onClose}
                                            defaultDate={value}
                                            onDateChange={onChange}
                                        />
                                    )}
                                />
                            </StyledShipmentFormSlider>
                        </FormProvider>
                    </NewShipmentFormContainer>
                </SliderContentContainer>
            )}
        </Slider>
    );
};
export default ShipmentFormSlider;
