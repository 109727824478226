import { sendSlackMessage } from 'api/slack';
import { Button } from 'components/Buttons';
import Modal, {
    ModalContent,
    ModalFooter,
    ModalHeader,
} from 'components/Modal';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text from 'components/Typography/Text';
import { getFrontendLanguage } from 'helpers/LanguageHelper';
import { usePersistentState } from 'hooks/usePersistentState';
import useSubscriptionTrialInfo from 'hooks/usePriceTierTrial';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import InfoBanner, { useTrialBanner } from './InfoBanner';
import { InfoText } from './StyledInfoBanner';

export default function ProTrialBanner() {
    const { t } = useTranslation('dashboard');

    const { showBanner, closeBanner } = useTrialBanner('trialBanner');

    const trialInfo = useSubscriptionTrialInfo();

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [hasSeenPayment, setHasSeenPayment] = usePersistentState(
        'seenPaymentModal',
        false
    );

    const onProTrialRequest = async () => {
        try {
            setIsLoading(true);
            await sendSlackMessage({ type: 'proTrialRequest' });
            setHasSeenPayment(true);
            setShowModal(false);
            closeBanner();
            toast.success(t('dashboard.proTrialModal.success'));
        } catch (error: any) {
            toast.error(t('dashboard.proTrialModal.error'));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (trialInfo?.status === 'ended' && !hasSeenPayment) {
            setShowModal(true);
        }
    }, [trialInfo]);

    const onClose = () => {
        // Assume that they have seen the payment modal only when they actively
        // close it. This is to ensure that we show the modal again if they
        // navigate away from the page.
        setHasSeenPayment(true);
        setShowModal(false);
    };

    if (!trialInfo) {
        return null;
    }

    const lang = getFrontendLanguage();
    if (!lang) {
        return null;
    }

    const allowedStatus = ['started', 'ending', 'ended'];
    if (!allowedStatus.includes(trialInfo.status)) {
        return null;
    }

    return (
        <>
            <InfoBanner show={showBanner} onClose={closeBanner}>
                <div>
                    <Heading size={HeadingSize.XSMALL}>
                        {t(
                            `dashboard.trialProBanner.${trialInfo.status}.title`
                        )}
                    </Heading>

                    <InfoText>
                        <Trans
                            t={t}
                            i18nKey={`dashboard.trialProBanner.${trialInfo.status}.subtitle`}
                        >
                            <SendifyProText />
                            <Button
                                variant="secondary"
                                onClick={() => setShowModal(true)}
                            />
                        </Trans>
                    </InfoText>
                </div>
            </InfoBanner>

            {!hasSeenPayment && (
                <Modal showModal={showModal} onClose={onClose} width="500px">
                    <ModalHeader title={t('dashboard.proTrialModal.title')} />
                    <ModalContent>
                        <Text>{t('dashboard.proTrialModal.description')}</Text>
                    </ModalContent>
                    <ModalFooter>
                        <MessageRequestButton
                            loading={isLoading}
                            onClick={onProTrialRequest}
                        >
                            {t('dashboard.proTrialModal.button')}
                        </MessageRequestButton>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}

const MessageRequestButton = styled(Button)`
    float: right;
`;

const SendifyProText = styled(Text)`
    background-color: ${({ theme }) => theme.colors.cardboardBrown300};
    color: black;
    padding: ${({ theme }) => theme.space.s1};
    text-decoration: underline;
`;
