import { useQuery } from '@tanstack/react-query';
import { getFavoritePackages } from 'api/packages';
import { FavoritePackage } from 'api/packages/types';

import { MetaData } from './../../api/types';

export const useFavoritePackages = (pageNo: number, perPage: number) => {
    const { data, ...rest } = useQuery<{
        packages: FavoritePackage[];
        meta: MetaData;
    }>(['favoritePackages', pageNo, perPage], () =>
        getFavoritePackages(perPage, pageNo)
    );
    return {
        packages: data?.packages,
        meta: data?.meta,
        ...rest,
    };
};
