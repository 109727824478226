import useSubscriptionTrialInfo from 'hooks/usePriceTierTrial';

import PlusTrialBanner from './PlusTrialBanner';
import ProTrialBanner from './ProTrialBanner';

export default function InfoBannerTrial() {
    const trialInfo = useSubscriptionTrialInfo();

    if (!trialInfo) {
        return null;
    }

    return (
        <>
            {trialInfo.productCode === 'pro_14_trial_global' ? (
                <ProTrialBanner />
            ) : (
                <PlusTrialBanner />
            )}
        </>
    );
}
