import Input from 'components/FormElements/Input';
import Switch from 'components/FormElements/Switch/Switch';
import { SwitchWrapper, Wrapper } from 'components/GoodsForm/StyledGoodsForm';
import DimensionsForm from 'components/NewShipmentForm/components/DimensionsForm/DimensionsForm';
import { FormPackage } from 'components/NewShipmentForm/types';
import Text, { TextSize } from 'components/Typography/Text';
import { trackSelectFavoritePackage } from 'external/analytics';
import { scrollToAndFocus } from 'helpers/FormHelper';
import { useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FavoritePackageSelect from '../NewShipmentForm/components/FavoritePackageSelect';
import StackableSelect from '../NewShipmentForm/components/StackableSelect';

interface Props {
    index: number;
    formId: string;
    readOnly?: boolean;
    canSavePackage?: boolean;
    showDescription?: boolean;
    pack: FormPackage;
    isHighlighted?: boolean;
}

const GoodsForm = ({
    index,
    pack,
    readOnly,
    formId,
    canSavePackage = true,
    showDescription = true,
    isHighlighted,
}: Props) => {
    const { t } = useTranslation('goods');

    const { control, errors, setValue, register } = useFormContext();

    const descriptionInputRef = useRef<HTMLInputElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const stackableRadioButtonRef = useRef<HTMLElement>(null);
    const [showingDescription, setShowingDescription] =
        useState(showDescription);
    const type = useWatch({
        control,
        name: `packages[${index}].type`,
        defaultValue: pack.type || 'PACKAGE',
    });

    const saveAsFavorite = useWatch({
        control,
        name: `packages[${index}].saveAsFavorite`,
        defaultValue: pack.saveAsFavorite,
    });

    return (
        <Wrapper
            data-testid={`goods-form-${index}`}
            data-cy={`goods-form-${index}`}
        >
            {!readOnly && (
                <FavoritePackageSelect
                    label={t('goods:favouritePackage')}
                    placeholder={t('goods:favouritePackagePlaceholder')}
                    onChange={(favorite) => {
                        if (!favorite) {
                            return;
                        }
                        trackSelectFavoritePackage(
                            favorite.value.id ?? -1,
                            formId,
                            index
                        );
                        const {
                            depthCm,
                            widthCm,
                            heightCm,
                            weightKg,
                            quantity,
                            type: favoriteType,
                            stackable,
                            description,
                        } = favorite.value;
                        setShowingDescription(true);

                        setValue(`packages[${index}].stackable`, stackable);
                        setValue(`packages[${index}].depthCm`, depthCm, {
                            shouldValidate: true,
                        });
                        setValue(`packages[${index}].widthCm`, widthCm, {
                            shouldValidate: true,
                        });
                        setValue(`packages[${index}].heightCm`, heightCm, {
                            shouldValidate: true,
                        });
                        setValue(`packages[${index}].weightKg`, weightKg, {
                            shouldValidate: true,
                        });
                        setValue(`packages[${index}].quantity`, quantity, {
                            shouldValidate: true,
                        });
                        setValue(`packages[${index}].type`, favoriteType, {
                            shouldValidate: true,
                        });
                        setValue(
                            `packages[${index}].description`,
                            description,
                            {
                                shouldValidate: true,
                            }
                        );
                    }}
                />
            )}

            <DimensionsForm
                readOnly={readOnly}
                pack={pack}
                index={index}
                prefix={`packages[${index}]`}
                isHighlighted={isHighlighted}
            />
            <Controller
                control={control}
                name={`packages[${index}].stackable`}
                rules={{
                    validate: (value) =>
                        type === 'PACKAGE' ||
                        value === false ||
                        value === true ||
                        t('errors.required').toString(),
                }}
                defaultValue={pack.stackable}
                onFocus={() => {
                    stackableRadioButtonRef.current?.scrollIntoView({
                        behavior: 'smooth',
                    });
                }}
                render={({ value, onChange }) => {
                    return (
                        <StackableSelect
                            ref={stackableRadioButtonRef}
                            value={value}
                            readOnly={readOnly}
                            packageType={type}
                            onChange={onChange}
                            errorMessage={
                                errors.packages?.[index]?.stackable?.message
                            }
                        />
                    );
                }}
            />

            <Controller
                control={control}
                name={`packages[${index}].description`}
                defaultValue={pack.description || ''}
                rules={{
                    required:
                        showingDescription && t('errors.required').toString(),
                    maxLength: {
                        value: 30,
                        message: t('errors.maxLength', {
                            count: 30,
                        }).toString(),
                    },
                }}
                onFocus={() => scrollToAndFocus(descriptionInputRef)}
                render={({ onChange, value, name }) => {
                    if (!showingDescription) {
                        return <></>;
                    }

                    return (
                        <Input
                            id={`packages-${index}-input-description`}
                            ref={descriptionInputRef}
                            label={t('goods:packageDescription')}
                            maxLength={30}
                            name={name}
                            onChange={onChange}
                            value={value}
                            error={errors.packages?.[index]?.description}
                            errorMessage={
                                errors.packages?.[index]?.description?.message
                            }
                        />
                    );
                }}
            />

            {canSavePackage && (
                <>
                    <SwitchWrapper>
                        <label htmlFor={`packages-${index}-saveAsFavorite`}>
                            <Text size={TextSize.MEDIUM}>
                                {t('goods:saveFavouriteButton')}
                            </Text>
                        </label>
                        <Controller
                            control={control}
                            name={`packages[${index}].saveAsFavorite`}
                            defaultValue={!!pack.saveAsFavorite}
                            render={({ onChange, value, name }) => (
                                <Switch
                                    ref={register()}
                                    id={`packages-${index}-saveAsFavorite`}
                                    onValueChange={onChange}
                                    name={name}
                                    checked={value}
                                />
                            )}
                        />
                    </SwitchWrapper>

                    <Controller
                        control={control}
                        name={`packages[${index}].name`}
                        defaultValue=""
                        rules={{
                            required: {
                                value: saveAsFavorite || false,
                                message: t('errors.required').toString(),
                            },
                        }}
                        onFocus={() => scrollToAndFocus(nameInputRef)}
                        render={({ value, onChange }) => (
                            <>
                                {saveAsFavorite && (
                                    <Input
                                        ref={nameInputRef}
                                        id={`packages-${index}-name`}
                                        label={t('goods:goodsName')}
                                        value={value}
                                        defaultValue={pack.name}
                                        onChange={onChange}
                                        error={!!errors.packages?.[index]?.name}
                                        errorMessage={
                                            errors.packages?.[index]?.name
                                                ?.message
                                        }
                                    />
                                )}
                            </>
                        )}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default GoodsForm;
