import { CourierCode } from 'api/consignments/types';
import { PackageRequest } from 'api/packages/types';
import { roundUpCost } from 'helpers/CurrencyHelper';
import {
    BookingConsignment,
    BookingOptions,
    TransportAlternative,
} from 'hooks/Booking/types';
import { Alpha2Code } from 'i18n-iso-countries';

/**
 * Returns the total cost including its base price and the cost of any selected addons.
 * The cost is rounded up, to nearest 0.1€ or 1SEK.
 *
 * @param transportAlternative The transport alternative you want the price of.
 * @param insuranceCost The cost of Sendify's insurance for this shipment. May be `undefined`,
 * if the user has opted out of insurance or you want to know the price without insurance.
 */
export const getProductCost = (
    transportAlternative: TransportAlternative,
    insuranceCost = 0
): number => {
    if (transportAlternative.addons.length > 0) {
        // Using the new addon flow - all addon costs are here.
        const addonCost = transportAlternative.addons.reduce(
            // The cost for addons with an unknown price are not known until after booking,
            // so we don't include them in the total cost. Instead we show a warning to the
            // user on the confirmation page.
            (acc, addon) => acc + (addon.price || 0),
            0
        );
        return roundUpCost(transportAlternative.customerCost + addonCost);
    }

    return roundUpCost(transportAlternative.customerCost) + insuranceCost;
};

/**
 *
 * @returns The total cost of the alternative, including the base price and the cost of any addons.
 * If the alternative has no alternative price, `undefined` is returned.
 */
export const getAlternativeCost = (
    transportAlternative: TransportAlternative
): number | undefined => {
    if (!transportAlternative.alternativePrice) {
        return undefined;
    }
    const addonCost = transportAlternative.addons.reduce(
        // The cost for addons with an unknown price are not known until after booking,
        // so we don't include them in the total cost. Instead we show a warning to the
        // user on the confirmation page.
        (acc, addon) => acc + (addon.price || 0),
        0
    );
    return roundUpCost(transportAlternative.alternativePrice.price + addonCost);
};

/**
 * Used for filtering, to remove undefined objects.
 */
export const isTransportAlternative = (
    alt: TransportAlternative | undefined
): alt is TransportAlternative => !!alt;

/**
 * An ADB is a German export/import document, required when
 *  - the shipment is sent to or from a non-EU country, and
 *  - the shipment weighs more than 1000 kg or has a value more than 1000 EUR.
 *
 * So far, we only support this for DSV and a certain select countries (Switzerland,
 * Norway, and the UK).
 *
 * If the user has selected to insure the shipment, we use that amount to determine
 * the goods value. Otherwise, the document page will have to ask the user about this.
 */
export const showADBOptions = (
    consignment?: BookingConsignment,
    selectedAlternative?: TransportAlternative,
    bookingOptions?: BookingOptions
) => {
    if (!consignment) {
        return false;
    }
    const supportedCountry = adbCountry(consignment);
    const supportedCarrier = adbSupportedCarrier(selectedAlternative);
    const requiredByWeight = adbRequiredByWeight(consignment);
    const requiredByValue = adbRequiredByValue(bookingOptions);
    const requiredByRegion = adbRequiredByRegion(consignment);
    return (
        supportedCountry &&
        supportedCarrier &&
        (requiredByRegion ||
            requiredByWeight ||
            !requiredByValue.determinate || // Show the options if we don't know if it's required
            (requiredByValue.determinate && requiredByValue.required))
    );
};

const adbSupportedCarrier = (selectedAlternative?: TransportAlternative) =>
    selectedAlternative?.product.courier.code === CourierCode.DSV ||
    selectedAlternative?.product.courier.code === CourierCode.DSV_GERMANY ||
    selectedAlternative?.product.courier.code === CourierCode.DSV_SWEDEN;

const adbCountry = (consignment: BookingConsignment) => {
    /**
     * In reality, this document is needed for all non-EU countries,
     * but we only have email addresses for Switzerland, Norway, and the UK.
     */
    const countries: Alpha2Code[] = ['CH', 'NO', 'GB'];
    const nonEUGermanImport =
        countries.includes(consignment.sender.address.countryCode) &&
        consignment.recipient.address.countryCode == 'DE';
    const nonEUGermanExport =
        consignment.sender.address.countryCode == 'DE' &&
        countries.includes(consignment.recipient.address.countryCode);

    return nonEUGermanImport || nonEUGermanExport;
};

type RequiredByValue =
    | { determinate: true; required: boolean }
    | { determinate: false };
export const adbRequiredByValue = (
    bookingOptions?: BookingOptions
): RequiredByValue => {
    const minGoodsValueExclusive = 1000; //In EUR
    const goodsValue = bookingOptions?.carrierAddons?.insurance?.amount;
    if (goodsValue !== undefined) {
        return {
            determinate: true,
            required: goodsValue > minGoodsValueExclusive,
        };
    }
    return { determinate: false };
};

export const adbRequiredByWeight = (consignment: BookingConsignment) => {
    const minWeightExclusive = 1000; //In kg
    const totalWeight = (consignment.packages as PackageRequest[]).reduce(
        (acc, p) => acc + p.quantity * p.weightKg,
        0
    );
    return totalWeight > minWeightExclusive;
};

export const adbRequiredByRegion = (consignment: BookingConsignment) => {
    if (
        consignment.sender.address.countryCode === 'GB' ||
        consignment.recipient.address.countryCode === 'GB'
    ) {
        return true;
    }
    return false;
};
